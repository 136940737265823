<template>
  <div>
    <div class="assignmentEvaluation">
      <div class="loading" v-if="fetchData">
        <load-data></load-data>
      </div>
      <div class="evaluate" v-else>
        <b-container fluid>
          <!-- <p>{{students}}</p> -->
          <!-- <p>{{ courseUsers }}</p>
        <p>{{ studentSheet }}</p> -->
          <!-- <p>{{ studentAnswer }}</p> -->
          <br />
          <b-row>
            <!-- <b-col md="4">
            <b-list-group>
              <b-list-group-item v-if="students.length == 0"
                >No Students Found!</b-list-group-item
              >
              <b-list-group-item
                class="mt-2"
                v-else
                v-for="(student, studentIndex) in students"
                :key="studentIndex"
              >
                <p
                  class="text-info btn p-0 m-0"
                  @click="getExamQuestions(student.pivot.institution_user_id)"
                >
                  {{ studentIndex + 1 }})
                  {{ student.first_name + " " + student.last_name }}
                </p>
              </b-list-group-item>
            </b-list-group>
          </b-col> -->
            <b-col md="12">
              <div class="loading" v-if="StudentExamSheet">
                <load-data></load-data>
              </div>
              <div v-else>
                <h3 class="text-center">Evaluation for {{ exam.name }}</h3>
                <h3>Questions:</h3>
                <div v-if="studentSheet.length != 0">
                  <div
                    class="studentSheet"
                    v-for="(sheet, sheetIndex) in studentSheet"
                    :key="sheetIndex"
                  >
                    <b-card no-body header-tag="header" footer-tag="footer">
                      <template #header>
                        <div class="question">
                          <p>Ques: {{ sheet.question }}</p>
                        </div>
                        <div
                          class="questionAttachments"
                          v-if="sheet.files.length != 0"
                        >
                          <p>Question Attachments:</p>
                          <div
                            class="attachments"
                            v-for="(file, fileIndex) in sheet.files"
                            :key="fileIndex"
                          >
                            <a
                              :href="
                                userAttachments +
                                  file.file_path +
                                  file.file_name
                              "
                              class="text-decoration-none"
                              target="_blank"
                              style="font-size: 16px"
                            >
                              {{ file.file_name }}
                            </a>
                          </div>
                        </div>
                      </template>
                    </b-card>
                  </div>
                </div>
                <br />
                <div class="studentSheets" v-if="studentAnswer.length != 0">
                  <hr style="background-color: #0275d8; height: 2px" />
                  <div class="answers">
                    <h3>Answers</h3>
                    <div
                      class="mt-2"
                      v-for="(answer, answerIndex) in studentAnswer"
                      :key="answerIndex"
                    >
                      <p>
                        Name: {{ answer.name != "" ? answer.name : "Student" }}
                      </p>
                      <p>
                        Ans:
                        {{
                          answer.answer != "" ? answer.answer : "Not answered"
                        }}
                      </p>
                      <div
                        class="attachments"
                        v-for="(file, fileIndex) in answer.studentFiles"
                        :key="fileIndex"
                      >
                        <a
                          :href="
                            userAttachments + file.file_path + file.file_name
                          "
                          class="text-decoration-none"
                          target="_blank"
                          style="font-size: 16px"
                        >
                          {{ file.file_name }}
                        </a>
                      </div>
                      <div class="assignmentScore">
                        <div class="d-flex align-items-center">
                          <b-form-input
                            class="text-center"
                            placeholder="Total Marks"
                            v-model="answer.score"
                            :disabled="answer.disabled"
                            style="width: 150px"
                          ></b-form-input>

                          <span
                            class="btn m-0 p-0 text-info ml-3"
                            @click="answer.disabled = false"
                            v-if="answer.disabled"
                            ><i class="fas fa-edit"></i
                          ></span>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                  <b-form-group>
                    <b-button
                      variant="primary"
                      @click="assignMarks()"
                      size="md"
                      :disabled="marksBtn.disabled"
                      >{{ marksBtn.text }}</b-button
                    >
                  </b-form-group>
                </div>
                <div class="noStudents" v-else>
                  <h6 class="text-danger">No Students Answered Yet!</h6>
                </div>
              </div>
              <br />
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  created() {
    this.fetchData = true;
    this.getExam();
    this.userAttachments = this.$store.getters.getAPIKey.userAttachments;
  },
  data() {
    return {
      fetchData: false,
      StudentExamSheet: false,

      userAttachments: "",
      currentMark: "0",
      exam: {},
      students: [],
      examQuestions: [],
      studentSheet: [],
      studentAnswer: [],
      courseUsers: {},
      marksBtn: {
        text: "Submit",
        disabled: false,
      },
    };
  },
  methods: {
    async assignMarks() {
      this.marksBtn.text = "Please Wait...";
      this.marksBtn.disabled = true;
      const studentScore = {
        assignment_scores: [],
      };
      await this.studentAnswer.forEach((student) => {
        if (student.studentId != "") {
          studentScore.assignment_scores.push({
            assignment_id: this.exam.id,
            student_id: student.studentId,
            score: student.score,
          });
        }
      });
      const url = this.$store.getters.getAPIKey.assignmentAnswers + `/marks`;
      axios.post(url, studentScore).then((response) => {
        this.clearResponse(this.studentSheet[0].studentId);
        response;
      });
    },
    getExam() {
      const assignmentId = this.$route.params.assignment_id;
      const url = this.$store.getters.getAPIKey.assignment + `/${assignmentId}`;
      axios.get(url).then((response) => {
        this.exam = {};
        this.exam = response.data;
        this.getCourse();
      });
    },
    getCourse() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/course/${this.exam.course_id}/users`;
      axios.get(url).then((response) => {
        this.students = response.data;
        this.students = this.students.filter((student) => {
          return student.pivot.courserole_id == 2;
        });
        this.students.forEach((student) => {
          this.courseUsers[student.pivot.institution_user_id] = {};
          this.courseUsers[student.pivot.institution_user_id].id =
            student.pivot.institution_user_id;
          this.courseUsers[student.pivot.institution_user_id].name =
            student.first_name + " " + (student.last_name || "");
        });
        if (this.students.length != 0) {
          this.getExamQuestions(this.students[0].pivot.institution_user_id, {
            submitted: true,
          });
        }

        this.fetchData = false;
      });
    },
    getStudentSheet() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/assignments/${this.exam.id}/assignmentresponses`;
      // const url =
      //   this.$store.getters.getAPIKey.mainAPI +
      //   `/assignments/${this.exam.id}/institutionusers/${studentId}/assignmentresponses`;
      axios.get(url).then((response) => {
        this.studentAnswer = [];
        let answers = response.data;
        if (answers.length != 0) {
          answers.forEach((answer) => {
            this.studentAnswer.push({
              name: this.courseUsers[answer.student_id]
                ? (answer.name = this.courseUsers[answer.student_id].name)
                : "",
              answer: answer.answer,
              studentId: answer.student_id,
              studentFiles: answer.files,
              score: answer.score != null ? Math.floor(answer.score) : null,
              disabled: answer.score != null ? true : false,
            });
          });
        }
        this.StudentExamSheet = false;
      });
    },
    getExamQuestions() {
      this.StudentExamSheet = true;

      const url =
        this.$store.getters.getAPIKey.assignment +
        `/${this.exam.id}/assignmentquestions`;
      axios.get(url).then((response) => {
        this.examQuestions = [];
        this.studentSheet = [];
        this.examQuestions = response.data;
        if (this.examQuestions.length != 0) {
          // this.answers = [];
          this.examQuestions.forEach((answer) => {
            this.studentSheet.push({
              // studentName: "",
              questionId: answer.id,
              // studentId: "",
              questionNumber: answer.question_no,
              question: answer.name,
              // answer: "",
              // marks: "",
              attachment: [],
              files: answer.files,
              studentFiles: [],
            });
          });
        }

        this.getStudentSheet();
      });
    },
    clearResponse() {
      this.marksBtn.text = "Submit";
      setTimeout(() => {
        this.getExamQuestions();
        this.marksBtn.disabled = false;
      }, 3000);
    },
  },
  watch: {
    studentSheet: {
      deep: true,
      handler: async function() {
        let answerMarks;
        // if (this.studentSheet.length != 0) {
        await this.studentSheet.forEach((mark) => {
          // if (mark.marks == "") {
          //   this.currentMark = "0";
          // }
          if (mark.marks != "") {
            answerMarks = mark.marks;
          }
        });
        if (answerMarks != undefined) {
          this.currentMark = this.currentMark + answerMarks;
        }

        // }
      },
    },
  },
};
</script>

<style >
.evaluate .col-md-4,
.evaluate .col-md-8 {
  height: 600px;
  overflow-y: scroll;
}
.assignmentEvaluation h3:not(:first-child),
.answers h3 {
  font-size: 18px;
  font-weight: 800;
}
</style>
